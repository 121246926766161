import React from "react";
import styled from "styled-components";
import { useWindowSize } from "react-use";

import nightly from '../../assets/partners/n1.png';
import algoblocks from '../../assets/partners/3.png';
import Aptos from '../../assets/partners/4.png';
import ethos from '../../assets/partners/5.png';
import vns from '../../assets/partners/6.png';
import misesBrower from '../../assets/partners/7.png';
import morphis from '../../assets/partners/8.png';
import sui from '../../assets/partners/9.1.png';
import veDao from '../../assets/partners/10.png';
import Psycho from '../../assets/partners/11.png';
import MarkerDao from '../../assets/partners/12.png';
import betamars from '../../assets/partners/13.png';
import dmal from '../../assets/partners/14.png';
import rainers from '../../assets/partners/15.png';
import oi from '../../assets/partners/16.png';
import solv from '../../assets/partners/17.png';
import arcana from '../../assets/partners/Arcana.png';
import AssureWallet from '../../assets/partners/Assure Wallet.png';
import Attic from '../../assets/partners/Attic.png';
import Blockombat from '../../assets/partners/Blockombat.png';
import Cetus from '../../assets/partners/Cetus.png';
import Debox from '../../assets/partners/Debox.png';
import Deri2 from '../../assets/partners/Deri 2.png';
import ERA from '../../assets/partners/ERA.png';
import EternalFiannce from '../../assets/partners/Eternal Fiannce.png';
import FentureFinance from '../../assets/partners/FentureFinance.png';
import Furion from '../../assets/partners/Furion 2.png';
import Ggslayer from '../../assets/partners/Ggslayer.png';
import JUJUBE from '../../assets/partners/JUJUBE.png';
import MagicSquare from '../../assets/partners/Magic Square.png';
// import Matespacecy from '../../assets/partners/Matespacecy.png';
import Max from '../../assets/partners/Max.png';
import Metamirror from '../../assets/partners/Metamirror.png';
import Metavoizz from '../../assets/partners/Metavoizz.png';
// import morphisWallet from '../../assets/partners/morphis wallet.png';
import olandbox from '../../assets/partners/olandbox.png';
import Quest3 from '../../assets/partners/Quest3.png';
import Starship from '../../assets/partners/Starship.png';
// import SuiWatcher from '../../assets/partners/Sui Watcher.png';
import Sui2 from '../../assets/partners/Sui2.png';
import Taskon from '../../assets/partners/Taskon.png';
import TimesFinance from '../../assets/partners/TimesFinance.png';
import Tocen from '../../assets/partners/Tocen.png';
import Tsunami from '../../assets/partners/Tsunami.png';
import web3MQ from '../../assets/partners/web3MQ.png';
import XLUS from '../../assets/partners/XLUS.png';
import Matespacecy from '../../assets/partners2/Matespacecy.png';
import Deri3 from '../../assets/partners2/Deri 1.png';
import xdx from '../../assets/partners2/0xdx.png';
import more from '../../assets/partners2/more.png';


import stratified from '../../assets/partners2/stratified.png';
import mexc from '../../assets/partners2/6.png';
import you from '../../assets/partners2/youbicapital.png'
import luci from '../../assets/partners2/3.png';
import lancer from '../../assets/partners2/2.png';
import ac from '../../assets/partners2/5.png';


import f3 from '../../assets/partners2/f3.png';
import okx from '../../assets/partners2/okx.png';
import fotor from '../../assets/partners2/fotor.png';
import republic from '../../assets/partners2/republic.png';
import { useState } from "react";
import { useEffect } from "react";


const section2 = [
    {
        "name": f3,
        "height": 50,
        "marginRight":112,
        "height2": 40,
        "marginRight2":97,
    },
    {
        "name": okx,
        "height": 42,
        "marginRight":78,
        "height2":33,
        "marginRight2":56
    },
    {
        "name": fotor,
        "height": 41,
        "marginRight":50,
        "height2": 30,
        "marginRight2":40
    },
    {
        "name": republic,
        "height": 47,
        "marginRight":0,
        "height2": 37,
        "marginRight2":0
    }
]

const sectionList = [
    {
        "name": luci,
        "height": 56,
        "height2":45,
        "marginRight":111,
        "marginRight2":89,
        
    },
    {
        "name": mexc,
        "height": 50,
        "marginRight":77,
        "height2":40,
        "marginRight2":61
    },
    {
        "name": you,
        "height": 47,
        "marginRight":33,
        "height2":37,
        "marginRight2":26
    },
   
    {
        "name": stratified,
        "height": 48,
        "marginRight":22,
        "height2":38,
        "marginRight2":17
    },
    {
        "name": lancer,
        "height": 45,
        "marginRight":40,
        "height2":36,
        "marginRight2":32
    },
    {
        "name": ac,
        "height": 42,
        "height2":33,
        "marginRight":0,
        "marginRight2":0
    }
]


const list = [
    {
        "name": AssureWallet,
        "height": 31,
        "height2":24,//1024
    },
    {
        "name": sui,
        "height": 33,
        "height2":26
    },
    {
        "name": Max,
        "height": 20,
        "height2":16
    },
    {
        "name": morphis,
        "height": 26,
        "height2":21
    },
    {
        "name": nightly,
        "height": 27,
        "height2":21
    },
    {
        "name": ethos,
        "height": 30,
        "height2":24
    },
    {
        "name": FentureFinance,
        "height": 27,
        "height2":21
    },
    {
        "name": misesBrower,
        "height": 23,
        "height2":18
    },
    {
        "name": Cetus,
        "height": 24,
        "height2":19
    },
    {
        "name": Metavoizz,
        "height": 34,
        "height2":27
    },
    {
        "name": MagicSquare,
        "height": 27,
        "height2":21
    },
    {
        "name": Tocen,
        "height": 28,
        "height2":23
    },
    {
        "name": dmal,
        "height": 28,
        "height2":22
    },
    {
        "name": arcana,
        "height": 22,
        "height2":17
    },
    {
        "name": solv,
        "height": 23,
        "height2":18
    },
    {
        "name": Tsunami,
        "height": 16,
        "height2":12
    },
    {
        "name": Taskon,
        "height": 23,
        "height2":18
    },
    {
        "name": Quest3,
        "height": 30,
        "height2":24
        },
    {
        "name": Attic,
        "height": 23,
        "height2":18
    },
    {
        "name": Debox,
        "height": 28,
        "height2":23
    },
    {
        "name": TimesFinance,
        "height": 28,
        "height2":23
    },
    {
        "name": oi,
        "height": 31,
        "height2":24
    },
    {
        "name": Furion,
        "height": 22,
        "height2":18
    },
    {
        "name": Metamirror,
        "height": 23,
        "height2":18
    },
    {
        "name": web3MQ,
        "height": 23,
        "height2":18
    },
    {
        "name": Blockombat,
        "height": 30,
        "height2":24
    },
    {
        "name": MarkerDao,
        "height": 28,
        "height2":22
    },
    {
        "name": JUJUBE,
        "height": 29,
        "height2":23
    },
    {
        "name": algoblocks,
        "height": 18,
        "height2":15
    },
    {
        "name": veDao,
        "height": 28,
        "height2":22
    },
    {
        "name": EternalFiannce,
        "height": 28,
        "height2":23
    },
    {
        "name": olandbox,
        "height": 17,
        "height2":14
    },
    {
        "name": Deri2,
        "height": 23,
        "height2":18
    },
    {
        "name": rainers,
        "height": 31,
        "height2":25
    },
    {
        "name": ERA,
        "height": 39,
        "height2":31
    },
    {
        "name": XLUS,
        "height": 35,
        "height2":28
    },
    {
        "name": betamars,
        "height": 14,
        "height2":11
    },
    {
        "name": Aptos,
        "height": 16,
        "height2":13
    },
    {
        "name": Psycho,
        "height": 39,
        "height2":31
    },
    {
        "name": vns,
        "height": 20,
        "height2":17
    },
    {
        "name": Matespacecy,
        "height": 39,
        "height2":31
    },
    {
        "name": xdx,
        "height": 30,
        "height2":24
    },

    {
        "name": Deri3,
        "height": 32,
        "height2":26
    },
    {
        "name": Sui2,
        "height": 37,
        "height2":30
    },
    {
        "name": Starship,
        "height": 37,
        "height2":30
    },
    // {
    //     "name": SuiWatcher,
    //     "height": 23
    // },

    {
        "name": Ggslayer,
        "height": 39,
        "height2":31
    },
    {
        "name": more,
        "height": 6,
        "height2":4
    }
]

/**
 * max-height:820px 的时候，logos减少一行
 */
const PartnersContainer = styled.div` 
position:relative ;
height:100vh ;
/*  ; */
background-color:#000 ;
box-sizing:border-box ;
display:flex ;
align-items:center ;


@media screen and (max-height:950px) and (min-width:1280px){
    padding-top:70px;
}
@media screen and (max-height:900px) and (min-width:1280px){
    padding-bottom:70px;
    align-items:flex-end;
}
@media screen and (max-height:850px) and (min-width:1280px){
    padding-bottom:28px;
    /* align-items:flex-end; */
    align-items:center;
}

/* @media screen and (max-height:820px) and (min-width:1280px){
   overflow-y:auto ;
} */
@media screen and (max-width:1280px){
    padding-top:100px;
    display:block;
}
@media screen and (max-width:1280px) and (max-height:720px){
    padding-top:80px;
    display:block;
}

@media screen and (max-width:1024px){
    padding-bottom:50px;
     overflow-y:auto ;
     overflow-x:hidden ; 
    
}


`
const Img = styled.img`
width:auto;
height:${props => props.height};
 `

const Inner = styled.div`
width:1280px ;
height:auto;
margin:0 auto ; 

box-sizing:border-box ;
@media screen and (max-width:1280px){
    width:100% ;
}
@media screen and (max-width:1024px){
    width:100%;
   
}


`
const PartnersLogo = styled.div` 
/* position:absolute ; */
margin-top:53px ;
/* margin-bottom:20px ; */
height:auto ;
@media screen and (max-width:1280px){
    width:100% ;
    bottom: 93px;
}
@media screen and (max-width:1024px){
    display:flex ;
    flex-direction:column ;
    justify-content:center ;
}
@media screen and (max-width:1000px){
    margin-top:35px ;
    margin-bottom:50px ;
}
`
const LogosContainer=styled.div`
width:100% ;
@media screen and (max-width:1280px){
   display:flex ;
   justify-content:center ;
}
 `
const Logos = styled.div`
/* 53-6-6 */
 margin-top: 47px;
 padding-left:55px ;
 display:grid ;
 grid-template-columns: 125px  152px  159px 138px 168px  145px  136px  158px;
 grid-template-rows:46px 49px 46px 39px  47px 42px;
 justify-items:center ;
 align-items:center ;
 /* background-color:pink ; */

 position:relative ;
&>div{
    width:100% ;
    position:absolute ;
    display:inline-block ;
    top:0;
    height:6px ;
    background-color:palegreen ;
}
@media screen and (min-width:1280px) and (max-height:820px){
    grid-template-rows:46px 49px 46px 39px  47px;
}
 /* width<1280 && width>1024 && height<=720 */
 @media screen and (max-width:1280px) and (min-width:1024px) and (max-height:720px){
    grid-template-rows:46px 49px 46px 39px;
}
@media screen and (max-width:1280px) and (min-width:1024px) and (max-height:620px){
    grid-template-rows:46px 49px 46px 39px;
}
 @media screen and (max-height:790px){
    margin-top: 30px;
 }
 @media screen and (max-width:1280px){
    margin-top: 43px;
    padding-left:0 ;
    grid-template-columns: 100px  122px  128px 111px 135px  115px  110px  126px;
    grid-template-rows:38px 41px 37px 31px  39px 37px;
 }


 @media screen and (max-width:1024px){
    margin-top: 43px;
    padding-left:0px ;
    grid-template-columns:repeat(7,auto);
    grid-template-rows:38px 41px 37px 31px  39px 37px;
    
    &>img:not(img:nth-child(7),img:nth-child(14),img:nth-child(21),img:nth-child(28),img:nth-child(35),img:nth-child(42)){
        margin-right:20px ;
    }
 }

@media screen and (min-width:1024px){
    &>img:first-child {
        justify-self: start;

    }
    &>img:nth-child(9) {
        justify-self: start;

    }
    &>img:nth-child(17) {
        justify-self: start;

    }
    &>img:nth-child(25) {
        justify-self: start;

    }
    &>img:nth-child(33) {
        justify-self: start;

    }
    &>img:nth-child(41) {
        justify-self: start;
        padding-left:20% ;

    }
}
@media screen and (max-width:900px) and (min-width:700px){
    margin-top: 43px;
    padding-left:0px ;
    grid-template-columns:auto auto auto auto auto;
    grid-template-rows:repeat(10,auto);

    &>img:not(first-child,img:nth-child(5),img:nth-child(10),img:nth-child(15),img:nth-child(20),
    img:nth-child(25),img:nth-child(30)){
        margin-right:20px ;
        margin-bottom:8px ;
    }
    &>img:first-child,img:nth-child(6),img:nth-child(11),img:nth-child(16),img:nth-child(21),img:nth-child(26),img:nth-child(31){
        justify-self:start ;
    }
    img:nth-child(36),img:nth-child(41),img:nth-child(46){
        
        justify-self:center ;
        padding-right:50px ;
    }
}
@media screen and (max-width:700px) and (min-width:390px){
    margin-top: 43px;
    padding-left:0px ;
    grid-template-columns:auto auto auto ;
    grid-template-rows:repeat(16,auto) ;

     &>img:not(first-child,img:nth-child(3),img:nth-child(6),img:nth-child(9),img:nth-child(12),img:nth-child(15),
    img:nth-child(18),img:nth-child(21),img:nth-child(24),img:nth-child(27),img:nth-child(30),
    img:nth-child(33),img:nth-child(36),img:nth-child(39),img:nth-child(42),img:nth-child(45),img:nth-child(48)){
        margin-right:20px ;
        margin-bottom:10px ;
    }
}

@media screen and (max-width:390px) and (min-width:260px){
    margin-top: 43px;
    padding-left:0px ;
    grid-template-columns:auto auto;
    grid-template-rows:repeat(24,auto) ;

    &>img:not(img:nth-child(even)){
        margin-right:30px ;
        margin-bottom:12px;
        padding:0 ;
     } 
}
@media screen and (max-width:1000px){
    margin-top: 35px;
}
   
  `

const Header = styled.div`
width:100% ;
text-align:center ;
font-size:40px ;
/* margin-top:79px ; */
font-family: 'Lato-Regular';
color:#fff ;
position:relative ;
&>div{
    width:100% ;
    position:absolute ;
    display:inline-block ;
    top:0;
    height:9px ;
    background-color:palegreen ;
}

@media screen and (max-width:1280px){
    font-size:32px ;
}
@media screen and (max-width:1280px){
    font-size:21px ;
}
 `
const SectionImg=styled.img`
width:auto;
height:${props => props.height};
margin-right:${props=>props.margin} ;
 `

 const FirstColumn=styled.div`
 width:100% ;
 display:flex ;
 @media screen and (max-width:1280px){
    justify-content:center ;
 }
 `
const Section = styled.div`
 margin-top: 48px;
 display:grid ;
 align-items:center ;
 padding-left:112px ;
 /* flex-wrap:wrap ; */
 grid-template-columns:auto auto auto auto  auto auto;

 
  @media screen and (max-width:1200px){
    padding-left:0px ;
  }

  @media screen and (max-width:1000px){
    grid-template-columns:auto auto auto;
    grid-template-rows:auto  ;
    
    margin-top: 34px;
    &>img:first-child,img:nth-child(2),img:nth-child(3){
        margin-bottom:15px ;
    }
    /* padding:0 50px ;
    display:flex ;
    flex-wrap:wrap ;
    align-items:flex-end ;
    justify-content:space-evenly;
    row-gap:14px; */
 }
 @media screen and (max-height:790px){
    margin-top: 52px;
 }
 @media screen and (max-width:450px){
    padding:0px ;
 }
  `

const Section2 = styled.div`
  display:grid ;
  align-items:center ;
  padding-left:234px ;
  /* @media screen and (min-width:1280px) and (max-height:800px){
    margin-top:8px ;
  } */
  grid-template-columns:auto auto auto auto  auto;
  @media screen and (max-width:1280px){
    padding:0 154px 0 187px ;
    margin-top:12px ;
 }

 @media screen and (max-width:1000px){
    grid-template-columns:auto auto;
    grid-template-rows:auto  ;
    &>img:first-child,img:nth-child(2){
        margin-bottom: 22px;
    }
    /* padding:0 50px ;
    display:flex ;
    flex-wrap:wrap ;
    align-items:flex-end ;
    justify-content:space-evenly;
    row-gap:14px; */
 }
 @media screen and (max-width:450px){
    padding:0px ;
 }
   `
const Text = styled.div`
margin-top:12px ;
margin-bottom:12px ;
text-align:center ;
white-space:pre-wrap ;
font-size:27px ;
font-family:Lato-Hairline ;
color:rgba(250,250,250,0.7) ;
/* @media screen and (min-width:1280px) and (max-height:800px){
    margin-top:3px ;
} */

/* background-color:pink ; */


position:relative ;

&>div{
    position:absolute ;
    width:100% ;
    height:6px ;
    background-color:palegreen ;
}
@media screen and (max-width:1280px){
    font-size:21px ;
    margin-top:5px ;
}
@media screen and (max-width:1000px){
    margin-top:25px;
    margin-bottom:19px ;
}
@media screen and (max-width:550px){
    font-size:14px ;
    color:#fff ;
    margin-top:23px;
    margin-bottom:12px ;
    font-weight:500 ;
}
`

const Title = styled.div`
width:100% ;
text-align:center ;
font-size:40px ;
font-family: 'Lato-Regular';
color:#fff ;
/* background-color:pink ; */
position:relative ;

&>div{
    width:100% ;
    position:absolute ;
    display:inline-block ;
    top:0;
    height:9px ;
    background-color:palegreen ;
}

@media screen and (max-width:1280px){
    font-size:32px ;
}
@media screen and (max-width:1000px){
    font-size:21px ;
}
 `



export function Partners() {
    const [logoList,setLogoList]=useState(list);

    const {width,height}=useWindowSize();

    useEffect(()=>{
        if(width>1280 && height<820){
            const lists=JSON.parse(JSON.stringify(list));
            const end=lists[lists.length-1];
            lists.splice(39,8);
            lists.push(end);
            setLogoList(lists);
        }
        if(width<=1280 && height>720){
            setLogoList(list)
        }
        if(width<1280 && width>1024 && height<=720){
            const lists=JSON.parse(JSON.stringify(list));
            const end=lists[lists.length-1];
            lists.splice(31,16);
            lists.push(end);
            setLogoList(lists);
        }
        if(width<1280 && width>1024 && height<=620){
            const lists=JSON.parse(JSON.stringify(list));
            const end=lists[lists.length-1];
            lists.splice(23,24);
            lists.push(end);
            setLogoList(lists);
        }
    },[width,height]);
    return (
        <PartnersContainer id="partners">
            <Inner >
                <Header>
                    {/* <div></div> */}
                    INSTITUTIONAL  INVESTORS
                </Header>
                <FirstColumn>
                    <Section>
                        {
                           sectionList.map((v, index) => {
                            if(width>550){
                                return <SectionImg src={v.name} 
                                height={width>1280?`${v.height}px`:`${v.height2}px`} 
                                margin={width>1280?`${v.marginRight}px`:`${v.marginRight2}px`}
                                key={index} />
                            }else{
                                return <SectionImg src={v.name} 
                                height={`${v.height2 - 10}px`} 
                                margin={`${v.marginRight2 - 10}px`}
                                key={index} />
                            }
                                
                            })
                        }
                       
                    </Section>
                </FirstColumn>

                {/* 不要修改Text,不要动，不要动 */}
                <Text>
                    {/* <div></div> */}
                    {`& individual investors who are the early supporters of  
renowned Web3 companies and crypto VCs
`}
                </Text>
                <FirstColumn>
                    <Section2>
                        {
                           section2.map((v, index) => {
                                if(width>550){
                                    return <SectionImg src={v.name}
                                    height={width > 1280 ? `${v.height}px` : `${v.height2}px`}
                                    margin={width > 1280 ? `${v.marginRight}px` : `${v.marginRight2}px`}
                                    key={index} />
                                }else {
                                    return <SectionImg src={v.name}
                                    height={ `${v.height2 - 10}px`}
                                    margin={ `${v.marginRight2 - 10}px`}
                                    key={index} />
                                }
                                })
                        }
                      
                    </Section2>
                </FirstColumn>
                <PartnersLogo>
                    <Title>
                        {/* <div></div> */}
                        PARTNERS
                    </Title>
                    <LogosContainer>
                        <Logos>
                            {/* <div></div> */}
                            {
                                logoList.map((v, index) => {
                                    return <Img src={v.name} height={width>1280?`${v.height}px`:`${v.height2}px`} key={index} />
                                    // if(width>390){
                                    //     return <Img src={v.name} height={width>1280?`${v.height}px`:`${v.height2}px`} key={index} />
                                    // }else{
                                    //     return <Img src={v.name} height={`${v.height}px`} key={index} />
                                    // }
                                    
                                })
                            }
                        </Logos>
                    </LogosContainer>
                </PartnersLogo>

            </Inner>

        </PartnersContainer>
    )
}