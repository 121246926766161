import Homepage from './Homepage';
import { Partners } from './comoments/Partners/index';

import styled from "styled-components";
import zkLogo from './assets/zk/zk.png';
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from 'react-use';
import { useCallback } from 'react';


const AppContainer = styled.div`
    height:100% ;
    background-color:#000 ;
 `
const Pages = styled.div`
height:100% ;
position:relative ;
`
const HeaderWrap = styled.header`
position:fixed;
left:50%;
transform:translateX(-50%);
width: 100%;
padding:23px 5vw 0 5vw;
height:76px;
display: flex;
justify-content: space-between;
align-items: center;
background-color:${props => props.bg === 1 ? "transparent" : "#000"} ;
z-index:999;
`;

const LogoWrap = styled.div`
width: 320px;
display: flex;
justify-content: flex-start;
align-items: center;
flex-wrap:nowrap;

font-size:30px ;
font-weight:600 ;
color:#fff;
`;

const Logo = styled.img`
width:23px ;
height:auto;
margin-right:4px ;
@media screen and (max-width: 720px){
  width: 20px;
}
`;
const LogoText = styled.div`
font-size: 20px;
font-family: 'fontspring-regular';
@media screen and (max-width: 750px){
font-size:14px ;
word-break: keep-all;
}
`
const AppButton = styled.button`
width: 171px;
height: 40px;
border-radius: 30px;
border: 1px solid #FFFFFF;
background: transparent;
white-space:pre-wrap ;


&:hover {
  cursor: pointer;
  background: #8060fe;
}

a{
  display:inline-block;
  width:100%;
  height:100%;
  color:#ffffff;
  font-size:18px;
  line-height:40px;
  font-family: 'fontspring-extralight';
  font-weight:normal;
  @media screen and (max-width: 720px){
    font-size: 16px;
  }

  @media screen and (max-width: 428px){
    font-size: 14px;
  }
}
`;


let clickScrolling=false;

function App() {
    const { height } = useWindowSize();
    const wrapperRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [scrolling,setScrolling]=useState(false);


    const onEnterNextPage=useCallback((event)=>{
        console.log(scrolling,'^^^^^^^^^^^^^^^^^^^^^^^^')
       if(!scrolling){
            event.stopPropagation();
            clickScrolling=true;
            const wrapper = wrapperRef.current;
            wrapper.style.transform = `translate3d(0px, -${height}px, 0px)`;
            wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
            setCurrentPage(2);
            setTimeout(()=>{
                clickScrolling=false;
            },800)
       }
    },[height,scrolling])

    const scrollUp=useCallback(()=>{
        if(!clickScrolling){
            setScrolling(true);
            const wrapper = wrapperRef.current;
            const partners = document.getElementById('partners');
            if (partners.scrollTop === 0 && currentPage === 2) {
                wrapper.style.transform = `translate3d(0px, 0px, 0px)`;
                wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
                // setCurrentPage(1)
                 setTimeout(()=>{
                    setCurrentPage(1);
                    setScrolling(false);
                },800)
            }
        }
    },[currentPage])


    const scrollDown = useCallback(() => {
        if (!clickScrolling) {
            setScrolling(true);
            const wrapper = wrapperRef.current;
            if (currentPage === 1) {
                wrapper.style.transform = `translate3d(0px, -${height}px, 0px)`;
                wrapper.style.transition = `all 800ms cubic-bezier(.34,.86,.71,.95) 0s`;
                setCurrentPage(2)
                setTimeout(() => {
                    setScrolling(false);
                    // setCurrentPage(2)
                }, 800)
            }
        }
    }, [currentPage, height])

    useEffect(()=>{
        const wrapper = wrapperRef.current;
        if(currentPage===1){
            wrapper.style.transform = `translate3d(0px, 0px, 0px)`;
        }else if(currentPage===2){
            wrapper.style.transform = `translate3d(0px, -${height}px, 0px)`;
            
        }
    },[height,currentPage])


    useEffect(() => {
        let scrollFunc = function (e) {
            let el = e || window.event;
                el.stopPropagation();
                if (el.wheelDelta) {
                    if (el.wheelDelta > 0) { //当鼠标滚轮向上滚动时
                        scrollUp()
                    }
                    if (el.wheelDelta < 0) { //当鼠标滚轮向下滚动时
                        scrollDown()
                    }
                } else if (e.detail) {
                    if (el.detail < 0) { //当鼠标滚轮向上滚动时
                        scrollUp()
                    }
                    if (el.detail > 0) { //当鼠标滚轮向下滚动时
                        scrollDown()
                    }
                }
        }
        // 给页面绑定鼠标滚轮事件,针对火狐的非标准事件 
         window.addEventListener("DOMMouseScroll", scrollFunc) // 给页面绑定鼠标滚轮事件，针对Google，mousewheel非标准事件已被弃用，请使用 wheel事件代替
         window.addEventListener("wheel", scrollFunc)   // ie不支持wheel事件，若一定要兼容，可使用mousewheel
         window.addEventListener("mousewheel", scrollFunc)
    }, [currentPage,scrollDown,scrollUp])


    return (
        <AppContainer >
            <HeaderWrap bg={currentPage}>
                <LogoWrap>
                    <Logo src={zkLogo}></Logo>
                    <LogoText>zkSync Name Service</LogoText>
                </LogoWrap>
                <AppButton onClick={(e) => { 
                    e.stopPropagation();
                    window.location.href = "https://app.zkns.domains/" 
                }}>
                    <a href="https://app.zkns.domains/">LAUNCH  APP</a>
                </AppButton>
            </HeaderWrap>
            <Pages ref={wrapperRef}>
                <Homepage nextPage={onEnterNextPage} />
                <Partners />
            </Pages>
        </AppContainer>
    );
}

export default App;
