import styled from "styled-components";
// import logo from "./assets/logo_slices/logo@2x.png";
import backgroundImg from "./assets/zk/themeBg.png";
import roundImg from './assets/zk/round.png';

// import {LogoCard} from './comoments/logoCard/index';
import {LogoCard} from './comoments/opLogoCard/index';
import {Footer} from './comoments/footer/index';
import { useWindowSize } from "react-use";
import { resizeListener } from './resize';
import { useEffect } from "react";


const Container = styled.div`
  height: 100vh;
  overflow: hidden;
  position:relative ;

  /* background-image:url(${backgroundImg}) ;
  background-size:cover; */

  background: url(${backgroundImg}) no-repeat center fixed;     
  background-size: cover;

`;

const Round=styled.img`
  position:absolute;
  top:calc(50% - 0px);
  left:calc(50% - 54px);
  transform:translate(-50%,-50%);
  z-index:88;
  width: 44.1875rem;

  @media screen and (max-width: 750px){
    top:calc(50% - 55px);
    left:calc(50% - 0px);
  }
`


const MainWrap = styled.div`
  width:100%;
  height:fit-content ;
  text-align:center;
  position: absolute;
  top:calc(50% - 120px);
  left:calc(50% - 54px);
  transform:translate(-50%,-50%);

  font-size: 50px;
  color: #ffffff;
  white-space:pre-wrap ;
  @media screen and (max-width: 1100px){
    left:calc(50% - 0px);
  }

  @media screen and (max-width: 750px){
    left:0 ;
    padding:0 30px ;
    transform:translateY(-50%);
  }
  @media screen and (max-width: 550px){
    font-size:36px;
    /* top:calc(50% - 35px); */
    top:calc(50% - 75px);
  }


  &>p{
    display:inline-block ;
    width: 670px;
    font-family:Lato-Regular ;
    height:fit-content ;
    

    @media screen and (max-width: 754px){
      width:100%;
      text-align: left;
    }
    &>span{
      width:100% ;
      display:inline-block ;
      text-align: right;
      font-family:Lato-Thin ;
      padding-right:10px ;
      /* padding-right:160px ; */
      @media screen and (max-width: 754px){
        padding-right:0px ;
      }

      @media screen and (max-width: 397px){
        white-space:pre-line ;
      }
      @media screen and (max-width: 365px){
        white-space:pre-line ;
      }
    }
  }
`;

const Logos=styled.div`
  position:absolute ;
  bottom: 125px;
  width: 100%;
  padding-right:54px ;
  @media screen and (max-width: 1100px){
    padding-right:0 ;
  }
  @media screen and (max-width: 750px){
    bottom:170px;
  }
  @media screen and (max-height: 896px){
    bottom:160px;
  }
  @media screen and (max-height: 844px){
    bottom:150px;
  }
  @media screen and (max-height: 780px){
    bottom:130px;
  }
  @media screen and (max-height: 680px){
    bottom:115px;
  }
  @media screen and (max-height: 568px){
    bottom:90px;
  }
 
 `

const FooterLogo=styled.div`
  position:absolute ;
  bottom: 40px;
  height:fit-content ;
  width: 100%;

  @media screen and (max-width: 750px){
    bottom:70px;
  }
  @media screen and (max-height: 896px){
    bottom:60px;
  }
  @media screen and (max-height: 844px){
    bottom:50px;
  }
  @media screen and (max-height: 780px){
    bottom:40px;
  }

  @media screen and (max-height: 568px){
    bottom:20px;
  }
 `
const mobailDesc='naming  service  on  zkSync,'
const Text=`leveraging  cross-chain
protocols`
const MobailText=`leveraging  
cross-chain
protocols`

const Homepage = (props) => {

  const {nextPage}=props;

  useEffect(() => {
    window.addEventListener('resize', resizeListener);
    resizeListener();
}, [])

const {width}=useWindowSize();

  return (
    <Container id="firstPage" onClick={(event)=>nextPage(event)}>
    
      <Round src={roundImg}/>
      {/* <BgImg src={backgroundImg} /> */}
      <MainWrap>
          <p>
            {mobailDesc}
            <span>{width>397?Text:MobailText}</span>
          </p>
      </MainWrap>
      <Logos>
          <LogoCard/>
      </Logos>
      <FooterLogo>
        <Footer/>
      </FooterLogo>
      
    </Container>
  );
};

export default Homepage;
